// @import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
//@import url('~bootstrap/dist/css');

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Jost:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500;600;700&display=swap');

@import 'owl.carousel/dist/assets/owl.carousel.css';
@import "../vendor/aos/aos.css";

@import "../vendor/bootstrap/css/bootstrap.css";
@import "../vendor/bootstrap-icons/bootstrap-icons.css";
@import "../vendor/boxicons/css/boxicons.css";
@import "../vendor/glightbox/css/glightbox.css";
@import "../vendor/remixicon/remixicon.css";
@import "../vendor/swiper/swiper-bundle.min.css";
@import "~tiny-slider/dist/tiny-slider.css";


@import "./styles.css";
@import "./custom.css";


//pages
@import 'root';
@import 'header';
@import 'home';
