
.fqs{
    font-family: 'Quicksand', sans-serif;
}
.header{
    font-family: 'Quicksand', sans-serif;
    background: #3d4d6a;
}
.page{
    margin: 20px 0 0 0;
    font-family: 'Quicksand', sans-serif;
    font-size: medium;
    font-weight: normal;
    line-height: 2rem;
    letter-spacing: 1px;
    word-spacing: 3px;
}

.section-title > * {
    font-family: 'Quicksand', sans-serif;
    font-size: medium;
    font-weight: normal;
}

.divider {
    flex-grow: 1;
    background-color: #f3f5fa;
    padding: 5px 0;
    overflow: hidden;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
}
