.sec-slider {
    position: relative;
    padding: 70px 0 0;

    .btn-move-down {
        position: absolute;
        bottom: 70px;
        left: 50%;
        transform: translateX(-50%);
        padding: 0;
        z-index: 2;
        font-size: 30px;
        color: #ffc107;
        display: none;
        @media (min-width: 768px) {
            bottom: 40px;
            display: inline-block;
        }
        @media (min-width: 992px) {
            bottom: 50px;
        }
    }
}

.full-owl-carousel {
    .item {
        position: relative;

        &:before {
            content: '';
            background: rgb(0 0 0 / 61%);
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;

        }


        .item-img {
            // height: 400px;
            // object-fit: cover;
            // object-position: center;

            // @media (min-width: 768px) {
            //     height: 600px;
            // }

            // @media (min-width: 1200px) {
            //     height: 700px;
            // }

            // @media (min-width: 1500px) {
            //     height: 850px;
            // }
        }

        .full-owl-text {
            position: absolute;
            right: 15px;
            top: 25px;
            color: #fff;
            letter-spacing: 2px;
            margin-bottom: 5px;
            z-index: 1;
        }

        .full-owl-container {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            margin: 0 auto;
            left: 0;
            right: 0;
            text-align: center;

            .full-owl-text-area {
                text-align: center;
                margin: 0 auto;
                display: inline-block;
                padding: 15px 25px;
                border-radius: 25px 0 25px 25px;
            }

            .full-owl-title {
                color: #fff;
                font-size: 26px;
                text-transform: uppercase;
                font-weight: 600;

                @media (min-width: 768px) {
                    font-size: 32px;
                }

                @media (min-width: 992px) {
                    font-size: 35px;
                }

                @media (min-width: 1200px) {
                    font-size: 50px;
                }
            }
        }
    }

    .owl-nav {
        button.owl-prev {
            position: absolute;
            left: 5px;
            top: 50%;
            color: #fff;
            border: 1px solid #fff;
            border-radius: 30px;
            width: 30px;
            height: 30px;
            font-size: 20px;
            background: rgb(255 255 255 / 20%);
        }

        button.owl-next {
            position: absolute;
            right: 5px;
            top: 50%;
            color: #fff;
            border: 1px solid #fff;
            border-radius: 30px;
            width: 30px;
            height: 30px;
            font-size: 20px;
            background: rgb(255 255 255 / 20%);
        }
    }

    .owl-dots {
        text-align: center;
        transition: all 0.5s;
        position: absolute;
        bottom: 10px;
        width: 100%;

        .owl-dot {
            height: 10px;
            width: 10px;
            background: rgb(255 255 255 / 10%);
            margin: 5px;
            border: 1px solid #fff;
            border-radius: 20px;
            transition: all 0.5s;

            &.active {
                width: 20px;
                transition: all 0.5s;
            }
        }
    }
}

//sec-testimonial
.sec-testimonial {
    padding: 60px 0;
}

.testimonial-owl-carousel {
    .item {
        position: relative;
        padding: 35px 10px 10px;

        .testimonial-box {
            border: 1px solid #ffffff;
            border-radius: 10px;
            padding: 60px 20px 20px;
            box-shadow: 0px 0px 12px 0px #ebebeb;

            .testimonial-img {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                border: 5px solid #f6f6f6;
                border-radius: 100px;
                padding: 3px;

                .item-img {
                    height: 70px;
                    width: 70px;
                    border-radius: 100px;
                    border: 1px solid #f6f6f6;
                }
            }
        }
        .testimonial-icon{
            text-align: center;
            color: #1c75bc;
            margin-bottom: 10px;
        }

        .testimonial-name {
            text-align: right;
            font-weight: 600;
        }

        .testimonial-text {
            text-align: center;
            margin-bottom: 10px;
        }
    }

    .owl-nav {
        button.owl-prev {
            position: absolute;
            left: -5px;
            top: 50%;
            color: #1c75bc;
            border: 1px solid #1c75bc;
            border-radius: 30px;
            width: 30px;
            height: 30px;
            font-size: 20px;
            background: rgb(255 255 255 / 20%);
        }

        button.owl-next {
            position: absolute;
            right: -5px;
            top: 50%;
            color: #1c75bc;
            border: 1px solid #1c75bc;
            border-radius: 30px;
            width: 30px;
            height: 30px;
            font-size: 20px;
            background: rgb(255 255 255 / 20%);
        }
    }

    .owl-dots {
        text-align: center;
        transition: all 0.5s;
        width: 100%;

        .owl-dot {
            height: 10px;
            width: 10px;
            background: rgb(255 255 255 / 10%);
            margin: 5px;
            border: 1px solid #1c75bc;
            border-radius: 20px;
            transition: all 0.5s;

            &.active {
                width: 20px;
                transition: all 0.5s;
            }
        }
    }
}
