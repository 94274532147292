.navbar {
    a {
        color: #444444;
        font-size: 16px;
        font-weight: 600;

        &:focus {
            color: #000;
        }
    }

    .nav-item-login {
        .nav-link{
            @media (min-width: 992px) {
                padding: 5px 20px;
                background: #ffc107;
                border-radius: 20px;
                margin-left: 25px;
            }
        }
    }

    .mobile-nav-toggle{
        color: #444444;
    }
    .mobile-nav-toggle.bi-x{
        color: #ffffff;
    }
}

.header {
    background: #ffffff;
}

#header {
    padding: 10px 0;

    .logo img {
        max-height: 55px;
    }
}

#header.header-scrolled,
#header.header-inner-pages {
    background: rgb(255 255 255 / 100%);
    box-shadow: 1px 5px 6px 0px #00000014;
}